<template>
    <div class="form-data">
        <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
            <el-form-item v-for="item in formConfig" :key="item.prop" style="width:100%" :label="item.label"
                :prop="item.prop">
                <template v-if="item.custom === 'input'">
                    <el-input v-model="form[item.prop]" :placeholder="item.placeholder" :show-password="item.password"></el-input> <span v-if="item.prop === 'price'" class="mark">{{
                        item.mark[form.type || type] }}</span>
                </template>
                <template v-else-if="item.custom === 'select'">
                    <el-select v-model="form[item.prop]" style="width:100%;margin-right:20px"
                        :placeholder="item.placeholder">
                        <el-option v-for="option in item.options" :key="option.value" :label="option.label"
                            :value="option.value">
                        </el-option>
                    </el-select>
                </template>
                <template v-else-if="item.custom === 'cascader'">
                    <el-cascader style="width: 100%;margin-right:20px" v-model="form[item.prop]" :options="item.options" :props="item.props" clearable :placeholder="item.placeholder"></el-cascader>
                </template>
                <template v-else-if="item.custom === 'picker'">
                    <el-date-picker :picker-options="item.pickerOptions" v-model="form[item.prop]" value-format="yyyy-MM-dd" type="date" :placeholder="item.placeholder"
                        style="width: 100%;"></el-date-picker>
                </template>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: 'FormData',
    props: {
        formConfig: {
            type: Array,
            default: () => [],
        },
        detail: {
            type: Object,
            default: () => {}
        },
        type: Number
    },
    data() {
        return {
            form: {},
            rules: {},
        }
    },
    created() {
        this.formConfig.forEach(item => {
            this.rules[item.prop] = item.rule
        })
        this.form = this.detail
    },
    methods: {
    }
}
</script>

<style scoped lang="scss">
::v-deep .el-form-item__content {
    display: flex;

    .el-input {
        margin-right: 20px;
    }

    .mark {
        white-space: nowrap;
    }
}
</style>