//进行axios二次封装:使用请求与响应拦截器
import axios from 'axios'
import utils from './index';
const httpInstance = axios.create({
    baseURL: '',
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
    },
})
httpInstance.interceptors.request.use((config) => {
    let token = utils.isLoginStatus();
    if (token) {
        config.headers['Authorization'] = token
        config.headers['uid'] = localStorage.getItem('mg-uid')
        config.headers['gid'] = localStorage.getItem('mg-gid')
        config.headers['plat'] = 'pc'
    }
    return config
}, err => {
    return Promise.reject(err)
});
httpInstance.interceptors.response.use(res => {
    return res.data
}, error => {
    console.log("error====", error);
    return Promise.reject(error)
})
export default httpInstance