import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import utils from '@/utils';
import validate from "@/utils/validate"
import {MouseRightBox} from "@/components/GoladUi"
import Pagination from "@/components/Pagination/Pagination"
import CopyText from "@/components/CopyText/CopyText"
import 'element-ui/lib/theme-chalk/index.css';
import "@/styles/global.scss"
import "@/assets/icon/index.css"
import dayjs from "dayjs"
import Directive from './directives'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(MouseRightBox)
Vue.use(Directive)
Vue.component(Pagination.name, Pagination);
Vue.component(CopyText.name, CopyText);
Vue.prototype.$dayjs = dayjs
Vue.prototype.$validate=validate
Vue.prototype.$utils=utils
const bus = new Vue()
Vue.prototype.$bus = bus
Vue.filter('dateTime',(str)=>dayjs.unix(str).format('YYYY-MM-DD'))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
