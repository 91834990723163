<template>
    <ul style="{ top: +top 'px', left: left + 'px' }" class="contextmenu">
        <li @click.stop="handleEdit(options)">编辑</li>
        <li @click.stop="handleDelete(options)">删除</li>
    </ul>
</template>

<script>
export default {
    name: 'MouseRightBox',
    components: {},
    props: {
        top: {
            type: Number,
            required: true
        },
        left: {
            type: Number,
            required: true,
        },
        options:{
            type:Object,
            required: true,
        },
        handleEdit: {
            type: Function,
        },
        handleDelete: {
            type: Function,
        },
    },
    mounted() {
        this.$nextTick(() => {
            let doc = document.querySelector('.contextmenu')
            doc.addEventListener("contextmenu",this.handleContextmenu,false)
        })
    },
    beforeDestroy(){
        this.$nextTick(() => {
            let doc = document.querySelector('.contextmenu')
            doc.removeEventListener("contextmenu",this.handleContextmenu,false)
        })
    },
    data() {
        return {

        }
    },
    methods: {
        handleContextmenu() {
            new Function("event.returnValue=false")
        }
    }
}
</script>
<style lang='scss' scoped>
.contextmenu {
    margin: 0;
    background: #fff;
    position: absolute;
    z-index: 3000;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);

    li {
        margin: 0;
        padding: 7px 16px;
        cursor: pointer;

        &:hover {
            background: #eee;
        }
    }
}
</style>