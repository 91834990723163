<template>
    <div class="detail-dialog">
        <dialog-box :visible="visible" :title="`修改密码`" width="670px">
            <div slot="content">
                <FormData :formConfig="formConfig" ref="formData" :detail="{}"></FormData>
            </div>
            <div slot="footer" class="footer">
                <basic-button color="#015C92" @handleClick="cancel">取消</basic-button>
                <basic-button background="#015C92" :plain="true" @handleClick="submit">确定</basic-button>
            </div>
        </dialog-box>
    </div>
</template>

<script>
import DialogBox from '@/components/DialogBox/DialogBox.vue'
import BasicButton from "@/components/BasicButton/BasicButton.vue"
import FormData from '@/components/FormData/FormData.vue'
import { updatePasswordApi } from '@/apis/system-setting/account-management'
export default {
    name: "PasswordDialog",
    components: { DialogBox, BasicButton, FormData },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            formConfig: [
                {
                    prop: 'old_password', label: '旧密码', custom: 'input', placeholder: '请输入旧密码',
                    rule: [
                        { required: true, message: '请输入旧的密码', trigger: 'blur' },
                    ]
                }, {
                    prop: 'new_password', label: '新密码', custom: 'input', placeholder: '请输入新的密码', password: true,
                    rule: [
                        { required: true, message: '请输入新的密码', trigger: 'blur' },
                        { min: 6, max: 16, message: '新密码长度在 6 到 16 个字符', trigger: 'blur' }
                    ]
                },
                {
                    prop: 'sec_password', label: '确认密码', custom: 'input', placeholder: '请再次输入密码', password: true,
                    rule: [
                        { required: true, message: '请再次输入密码', trigger: 'blur' },
                        {
                            validator: (rule, value, callback) => {
                                if (value !== this.$refs.formData.form.new_password) {
                                    callback(new Error('两次密码不一致,请重新输入'));
                                } else {
                                    callback()
                                }
                            }, trigger: 'blur'
                        }
                    ]
                },
            ], // 表单配置项
            passwordParams: {} 
        }
    },
    created() {
    },
    methods: {
        submit() {
            this.$refs.formData.$refs.form.validate(async (valid) => {
                if (valid) {
                    const params = JSON.parse(JSON.stringify(this.$refs.formData.form))
                    this.passwordParams.old_password = this.$utils.Md5(params.old_password)
                    this.passwordParams.new_password = this.$utils.Md5(params.new_password)
                    const res = await updatePasswordApi(this.passwordParams)
                    if (res.code === 1) {
                        this.$message.success(`密码修改成功`)
                        this.$emit('update:visible', false)
                    }
                }
            });
        },
        cancel() {
            this.$refs.formData.$refs.form.resetFields()
            this.$emit('update:visible', false)
        },
    }
}
</script>

<style scoped lang="scss">
.detail-head {
    display: flex;
    justify-content: space-between;
    height: 38px;
    padding: 0 20px;
    line-height: 38px;
    font-size: 14px;
    background: #e4e7e9;

    .title {
        margin-right: 4px;
        color: #6F7071;
    }
}

.detal {
    margin: 20px 0 10px;
    font-size: 14px;
}</style>