import httpRequest from '@/utils/httpRequest'

// 用户列表
export const userListApi = values => httpRequest.post('/api/user/list', values)

// 部门列表
export const departListApi = values => httpRequest.post('/api/depart/list', values)

// 删除部门
export const delDepartApi = values => httpRequest.post('/api/depart/del', values)

// 编辑/新增部门
export const handleDepartApi = values => httpRequest.post('/api/depart/edit', values)

// 删除用户
export const delUserApi = values => httpRequest.post('/api/user/del', values)

// 新增/编辑用户
export const handleUserApi = values => httpRequest.post('/api/user/edit', values)

// 重置密码
export const resetPasswordApi = values => httpRequest.post('/api/account/pass/reset', values)
