<template>
    <div v-if="visible" :class="['message-box', visible ? 'is-active' : '']">
        <transition name="fade-transform" mode="out-in">
            <div class="inner" :style="{ 'width': width }">
                <header>
                    <h1 class="title">{{ title }}</h1>
                    <slot name="close"></slot>
                </header>

                <div class="content">
                    <slot name="content"></slot>
                </div>
                <div class="foot" v-if="footShow">
                    <slot name="footer"></slot>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'DialogBox',
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
        footShow: {
            type: Boolean,
            default:()=>true,
        },
        title: {
            type: String,
            default: '标题名称'
        },
        content: {
            type: String,
            default: '这是一段内容'
        },
        close: {
            type: Function,
        },
        width: {
            type: String,
            default: '400px'
        }
    },
    data() {
        return {

        }
    },
    methods: {
        hideMessageBox() {
            this.$messageBox.hide(() => {
                this.close && this.close();
            })
        }
    }
}
</script>
<style lang='scss' scoped>
.message-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 2001;
    display: none;

    .inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 500px;
        background-color: #fff;
        overflow: hidden;

        header {
            position: relative;
            display: flex;
            justify-content: space-between;
            padding: 25px;
            padding-bottom: 0;
            color: #00040C;

            h1 {
                position: relative;
                font-size: 15px;
                margin: 0;
                padding-left: 13px;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: block;
                    width: 4px;
                    height: 100%;
                    background-color: var(--color);
                }
            }

            .icon-close {
                font-size: 21px;
                font-weight: bold;
                cursor: pointer;
            }
            
        }

        .content {
            width: 100%;
            // min-height: 150px;
            margin-top: 10px;
            padding: 15px 38px 15px;
            box-sizing: border-box;
        }

        .foot {
            padding: 15px 38px 15px;
        }
    }
}

.is-active {
    display: block;
}</style>