<template>
    <el-tooltip class="item" effect="dark" :content="text" placement="top">
        <div class="text" @click="handleCopy"  title="点击复制">{{ text }}</div>
    </el-tooltip>
</template>

<script>
import copy from 'copy-to-clipboard';
export default {
    name: 'CopyText',
    props: ["text"],
    data() {
        return {

        }
    },
    methods: {
        handleCopy() {
            let isSuccess = copy(this.text, {
                debug: true,
                message: 'Press #{key} to copy',
            });
            if (isSuccess) {

            }
        }
    }
}
</script>
<style lang='scss' scoped>
    .text{
        width:100%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }

</style>