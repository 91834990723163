<template>
    <div class="box-main">
        <basic-menu></basic-menu>
        <div class="main-content" ref="elMain">
            <BasicHeader />
            <div class="content-main">
                <div class="crumb-box">
                    <BasicBreadcrumb />
                </div>
                <div class="view-main">
                    <transition name="fade-transform" mode="out-in">
                        <router-view></router-view>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import BasicHeader from "./components/BasicHeader.vue";
import BasicMenu from "./components/BasicMenu.vue";
import BasicBreadcrumb from './components/BasicBreadcrumb.vue';
export default {
    components: { BasicHeader, BasicMenu, BasicBreadcrumb },
    name: "Layout",
    data() {
        return {
            transitionName: "",
            top: 0,
        };
    },
    watch: {
        //   $route(to, from) {
        //     //如果to索引大于from索引,判断为前进状态,反之则为后退状态
        //     if (to.meta.index > from.meta.index) {
        //       this.transitionName = "slide-left";
        //     } else {
        //       this.transitionName = "slide-right";
        //     }
        //   },
    },
    created() {
        this.tableHeightHandle()
        //   this.$EventBus.$on("topup", () => {
        //     let uptop = setInterval(() => {
        //       let top = this.$refs.elMain.scrollTop
        //       if (top > 100) {
        //         this.$refs.elMain.scrollTop = top - 100
        //       } else {
        //         this.$refs.elMain.scrollTop = 0
        //         clearInterval(uptop);
        //       }
        //     }, 10)
        //   })
    },
    mounted() { },
    methods: {
        tableHeightHandle() { // 监听窗口大小变化自动调整表格最大高度
            window.onresize = () => {
                this.$store.commit('setTableHeight', document.documentElement.clientHeight - 300)
            }
        },
        getScroll(e) {

        }
    },
};
</script>
  
<style lang="scss" scoped>
.box-main {
    width: 100vw;
    height: 100vh;
    display: flex;
    position: relative;
}

.main-content {
    flex: 1;
    background-color: #F3F3F3;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
}
.content-main {
    position: relative;
    padding: 10px 15px;
    background-color: #F3F3F3;
}

.crumb-box{
    background-color: #F3F3F3;
}
.view-main{
    // padding: 12px;
    height: calc(100vh - 115px);
}

/* fade-transform */
.fade-transform--move,
.fade-transform-leave-active,
.fade-transform-enter-active {
    transition: all 0.5s;
}

.fade-transform-leave-active {
    position: absolute;
}

.fade-transform-enter {
    opacity: 0;
    transform: translateX(-30px);
}

.fade-transform-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

::v-deep .el-menu {
    border-right: none;
}
</style>