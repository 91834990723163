import _MouseRightBox from "./MouseRightBox.vue"
export default {
    install(Vue){
        let mouseRightBox = null
        Vue.component(_MouseRightBox.name, _MouseRightBox);
        Vue.prototype.$mouseRightBox = {
            show,
            hide,
        }
        function show(element,props, callback){
            if(!element.className) return;
            mouseRightBox=element.querySelector(".contextmenu");
            if (!mouseRightBox) {
                let mouseRightBox = Vue.extend({
                    render(h) {
                        return h('mouse-right-box',{
                            props: { ...props }
                        })
                    }
                })
                mouseRightBox = new mouseRightBox()
                this.vm = mouseRightBox.$mount();
                element.appendChild(this.vm.$el)
                callback && callback()
            }
        }
        function hide(element,callback) {
            element.removeChild(this.vm.$el)
            mouseRightBox.$destroy();
            mouseRightBox = null;
            this.vm = null;
            callback && callback()
        }

    }
}