import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    systemInfo: JSON.parse(localStorage.getItem('system-info')), // 系统信息
    roleList: JSON.parse(localStorage.getItem('role-list')), // 角色列表
    maxHeight: document.documentElement.clientHeight - 300, //  table最大高度
  },
  mutations: {
    setTableHeight(state, height) {
      state.maxHeight = height
    },
    setSystemInfo(state, systemInfo) {
      state.systemInfo = systemInfo
    },
    setRoleList(state, roleList) {
      state.roleList = roleList
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
  }
})
