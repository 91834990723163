const validate = {
  mobile: (rule, value, callback) => {
    const reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/

    if (!reg.test(value)) {
      callback(new Error('请输入正确的手机号'))
    } else {
      callback()
    }
  },
  tel: (rule, value, callback) => {
    const reg = /^(?:(?:\d{3}-)?\d{8}|^(?:\d{4}-)?\d{7,8})(?:-\d+)?$/
    if (value &&  !reg.test(value)) {
      callback(new Error('请输入正确的固定电话'))
    } else {
      callback()
    }
  },
  mobileOrTel: (rule, value, callback) => {
    const mobileReg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/
    const TelReg = /^(?:(?:\d{3}-)?\d{8}|^(?:\d{4}-)?\d{7,8})(?:-\d+)?$/
    if (!mobileReg.test(value) && !TelReg.test(value)) {
      callback(new Error('请输入正确的电话号码'))
    } else {
      callback()
    }
  },
  password: (rule, value, callback) => {
    const reg = /[a-zA-Z]+(?=[0-9]+)|[0-9]+(?=[a-zA-Z]+)/g

    if (!reg.test(value) || value.length < 6 || value.length > 15) {
      callback(new Error('请将密码设置为6-15位，以字母开头并且包含字母和数字'))
    } else {
      callback()
    }
  },
  money:( rule, value, callback)=>{
    const reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/
    if (!reg.test(value) || value.length < 6 || value.length > 15) {
      callback(new Error('请输入正确的金额,整数或者保留两位小数'))
    } else {
      callback()
    }
  }
}

export default validate
