<template>
    <div class="pagin">
        <el-pagination class="pagination-box" background @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-sizes="[10, 20]"
            :page-size="pageSize" layout="sizes, prev, pager, next" :total="total">
        </el-pagination>
    </div>
</template>

<script>

export default {
    name: 'Pagination',
    components: {},
    props: {
        total: {
            type: Number,
            required: true,
        },
        pageSize: {
            type: Number,
            required: true,
        },
        page: {
            type: Number,
            required: true,
        }

    },
    watch: {
        page: {
            immediate: true,
            handler(value) {
                this.currentPage = value
            },
            deep: true,
        }
    },
    data() {
        return {
            currentPage: 1,
        }
    },
    methods: {
        handleSizeChange(val) {
            this.$emit("changePageSize", val)
        },
        handleCurrentChange(val) {
            this.$emit("changePage", val)
        },
    }
}
</script>
<style lang='scss' scoped>
.pagination-box {
    // position: absolute;
    // left: 0;
    // bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    margin: 20px 0 10px;
}
</style>