<template>
    <div class="basic-menu">
        <div class="logo">
            <img :src="'https://' + $store.state.systemInfo.logo" alt="">
        </div>
        <el-scrollbar wrap-class="scrollbar-wrapper">
            <el-menu :collapse-transition="false" active-text-color="#2D82B5" :default-active="defaultactive"
            :default-openeds="defaultOpeneds"
                class="el-menu-vertical-demo" @select="selectMenu">
                <template v-for="(itemOne, indexOne) of routerlist">
                    <template v-if="itemOne.children?.length">
                        <template v-if="!itemOne.children[0].meta.isMenu&&itemOne.children.length == 1">
                            <el-menu-item :index="itemOne.path" :key="itemOne.path"
                                @click="handleClickRouter(itemOne.path)">
                                <!-- <img :class="['icon']" :src="emun[itemOne.meta.icon]" alt=""> -->
                                <span :class="['icon', `icon-${itemOne.meta.icon}`]"></span>
                                <span slot="title">{{ itemOne.meta.title }}</span>
                            </el-menu-item>
                        </template>
                        <template v-else>
                            <el-submenu :index="itemOne.path" :key="itemOne.path">
                                <template slot="title">
                                    <!-- <img :class="['icon']" :src="emun[itemOne.meta.icon]" alt=""> -->
                                    <span :class="['icon', `icon-${itemOne.meta.icon}`]"></span>
                                    <span>{{ itemOne.meta.title }}</span>
                                </template>
                                <template v-for="(itemtwo, indextwo) of itemOne.children">
                                    <el-menu-item v-if="itemtwo.meta.isMenu" :index="itemtwo.path" :key="indextwo"
                                        @click="handleClickRouter(itemtwo.path)">{{
                                            itemtwo.meta.title }}</el-menu-item>
                                </template>
                            </el-submenu>
                        </template>
                    </template>
                </template>
            </el-menu>
        </el-scrollbar>
    </div>
</template>

<script>
import { getRole } from '@/utils/hook'
export default {
    name: 'BasicMenu',
    components: {},
    data() {
        return {
            emun: {
                districtMange: require("@/assets/images/districtMange.png"),
                districtMangeActive: require("@/assets/images/districtMange_active.png"),
                electricEquipment: require('@/assets/images/electricEquipment.svg'),
                costAssistance: require('@/assets/images/costAssistance.png'),
                topUpAndPay: require('@/assets/images/topUpAndPay.png')
            },
            defaultactive: this.$route.path,
            defaultOpeneds:['/', '/district-mange/district-set'],
            routerlist: []
        }
    },
    // watch: {
    //     $route: {
    //         handler(newdata, olddata) {
    //             // console.log(newdata);
    //             // this.defaultactive = newdata.path
    //         },
    //         immediate: true,
    //         deep: true,
    //     },
    // },
    computed: {
    },
    async created() {
        await getRole()
        this.routerlistHandle()
        if(sessionStorage.getItem("path")){
            // console.log(JSON.parse(sessionStorage.getItem("path")));
            
            this.defaultOpeneds=JSON.parse(sessionStorage.getItem("path"));
        }
    },
    methods: {
        routerlistHandle() {
            const roleList = this.$store.state.roleList
            let list = JSON.parse(JSON.stringify(this.$router.options.routes))
            list.forEach(item => {
                item.children = item.children && item.children.filter(eleitem => roleList.includes(eleitem.meta.right))
            })
            list = list.filter(item => (item.children?.length > 0) || roleList.includes(item.meta.right))
            
            this.routerlist = list

        },
        selectMenu(index,path){
            sessionStorage.setItem("path",JSON.stringify(path))
        },
        handleClickRouter(path) {
            path && this.$router.push(`${path}`);
        }
    }
}
</script>
<style lang='scss' scoped>
.basic-menu {
    width: 180px;
    height: 100vh;
    overflow: auto;
    .logo {
        display: block;
        width: 100%;
        text-align: center;
        line-height: 60px;
        height: 60px;

        img {
            display: block;
            width: 100%;
        }
    }
    .icon {
        margin-right: 8px;
    }
}


::v-deep .el-submenu__title {
    font-size: 13px;
}

::v-deep .el-submenu .el-menu-item {
    box-sizing: border-box;
    width: 100%;
    padding: 0;
    min-width: auto;
}

::v-deep .is-active .el-submenu__title {
    background-color: #53A7D8;
    color: #fff;

    .icon {
        fill: #fff;
        color: #fff;
        filter: brightness(100);
    }
}

::v-deep .is-opened ul.el-menu {
    background-color: #FBFBFB;
}

::v-deep .el-menu-item:focus,
.el-menu-item:hover {
    outline: 0;
    color: #53A7D8;
    background-color: #FBFBFB;

}

::v-deep .el-menu-item.is-active {
    background-color: #53A7D8;
    color: #fff !important;
}

::v-deep .is-opened .el-menu-item.is-active {
    background-color: transparent;
    color: #53A7D8 !important;
}

::v-deep .is-active .el-submenu__title i {
    color: #fff;
}
::v-deep .el-scrollbar {
    width: 100%;
    height: calc(100vh - 60px);
}
// .el-scrollbar__wrap{
//     overflow-x: hidden !important;
// }
</style>