<template>
    <div>
        <button :style="{
            'color':plain?'#fff':'#3285B7',
            'fill':fillColor,
            'backgroundColor':plain?fillColor:'#fff',
            }"
            @click="handleClickEvent"
            >
            <slot></slot>
        </button>
    </div>
</template>

<script>

export default {
    name: 'BasicButton',
    components: {},
    props:{
        color:{
            type:String,
            default:'#fff',
        },
        fillColor:{
            type:String,
            default:'#3285B7',
        },
        background:{
            type:String,
        },
        plain:{
            type:Boolean,
        }
    },
    data() {
        return {
           
        }
    },
    mounted(){
        
    },
    methods: {
        handleClickEvent(){
            this.$emit('handleClick')
        }
    }
}
</script>
<style lang='scss' scoped>
button {
    white-space:pre;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 0px 12px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 1px 1px 1px 1px;
    opacity: 1;
    border: 1px solid #3285B7;
    cursor: pointer;
    font-size:12px;
    border-radius:3px;
    // &:nth-child(1) {
    //     margin-right: 12px;
    // }
   &:link{
    opacity: .8;
   }
}
</style>