import httpRequest from '@/utils/httpRequest'

// 上传图片
export const uploadLogoApi = values => httpRequest.post('/api/system/upload', values)

// 获取系统信息
export const getSystemApi = values => httpRequest.post('/api/system/get', values)

// 设置系统信息
export const setSystemApi = values => httpRequest.post('/api/system/set', values)
