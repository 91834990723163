<template>
    <div class="breadcrumb-box">
        <el-breadcrumb separator="">
            <template v-for="(item, key) of $route.matched">
                <el-breadcrumb-item v-if="!item.meta.isBreadcrumb" :class="key === 0 ? 'active' : 'fontstyle'">{{ item.meta.title }}</el-breadcrumb-item>
            </template>
        </el-breadcrumb>
    </div>
</template>

<script>

export default {
    name: 'BasicBreadcrumb',
    components: {},
    data() {
        return {

        }
    },
    watch: {
        // $route: {
        //     handler(newdata, olddata) {
        //         console.log(newdata);
        //         // this.defaultactive = newdata.path
        //     },
        //     immediate: true,
        //     deep: true,
        // },
    },
    created() {
        // console.log(this.$route.matched);
    },
    methods: {

    }
}
</script>
<style lang='scss' scoped>
.breadcrumb-box {
    padding: 0 12px;
}
::v-deep  .el-breadcrumb__inner{
    font-weight: 400;
    font-weight: bold;
    color: #000;
    font-size: 14px;
}
::v-deep .active .el-breadcrumb__inner{
    font-weight: 800;
    font-weight: bold;
    color: #000;
    font-size: 20px;
    height: 40px;
    line-height: 40px;
}
::v-deep .el-breadcrumb__item{
    float: none;
}
</style>