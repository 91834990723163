import httpRequest from '@/utils/httpRequest'

// 账号列表
export const accountListApi = values => httpRequest.post('/api/account/list', values)

// 删除账号
export const delAccountApi = values => httpRequest.post('/api/account/del', values)

// 创建/编辑账号
export const handleAccountApi = values => httpRequest.post('/api/account/edit', values)

// 关联房间
export const relevanceRoomApi = values => httpRequest.post('/api/account/getrooms', values)

// 移除房间关联
export const removeRelevanceApi = values => httpRequest.post('/api/account/removeroom', values)

// 获取账号权限配置
export const accountRoleApi = values => httpRequest.post('/api/account/getrights', values)

// 设置账号权限
export const setRoleApi = values => httpRequest.post('/api/account/setrights', values)

// 修改密码
export const updatePasswordApi = values => httpRequest.post('/api/account/updatepassword', values)

// 账号关联房间
export const accountRelevanceRoomApi = values => httpRequest.post('/api/account/setroom', values)
