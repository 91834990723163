import Vue from 'vue'
import VueRouter from 'vue-router'
import utils from "@/utils"
import Layout from '@/layouts/index.vue'

Vue.use(VueRouter)
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

const routes = [
    {
        path: '/',
        component: Layout,
        redirect: "/district-mange/district-set",
        meta: {
            title: "区域管理",
            requireAuth: true,
            isMenu: true,
            icon: 'districtMange',
            right: 'AREA_MANAGE'
        },
        children: [{
            path: '/district-mange/district-set',
            name: 'DistrictMange',
            component: () => import('@/views/district/districtSet.vue'),
            meta: {
                title: "区域配置",
                requireAuth: true,
                isMenu: true,
                right: 'AREA_CONFIG'
            }
        },
        {
            path: '/district-mange/roomm-mnagement',
            name: 'roommMnagement',
            component: () => import('@/views/district/roommMnagement.vue'),
            meta: {
                title: "房间管理",
                requireAuth: true,
                isMenu: true,
                right: 'ROOM_CONFIG'
            },
        },
        {
            path: '/district-mange/roominfo/:id',
            name: 'roomInfo',
            component: () => import('@/views/district/roomInfo/index.vue'),
            meta: {
                title: "房间详情",
                requireAuth: true,
                isMenu: false,
            } 
        },
        // {
        //     path: '/district-mange/roominfo/:id',
        //     name: 'roomInfo',
        //     component: {
        //         render(c) {
        //             return c('router-view')
        //         }
        //     },
        //     meta: {
        //         title: "房间管理",
        //         requireAuth: true,
        //         isMenu: false,
        //     },
        //     children: [
        //         {
        //             path: '/district-mange/roominfo/:id',
        //             name: 'roomInfo',
        //             component: () => import('@/views/district/roomInfo/index.vue'),
        //             meta: {
        //                 title: "房间详情",
        //                 requireAuth: true,
        //                 isMenu: false,
        //             }
        //         },
        //     ]
        // },
        ]
    },
    {
        path: '/electric-equipment',
        component: Layout,
        redirect: "/electric-equipment/communication-terminal",
        meta: {
            title: "用电设备",
            requireAuth: true,
            isMenu: true,
            icon: 'electricEquipment',
            right: 'ELE_DEVICES'
        },
        children: [
        //     {
        //     path: '/electric-equipment/communication-terminal',
        //     name: 'communicationTerminal',
        //     component: () => import('@/views/electricEquipment/communicationTerminal'),
        //     meta: {
        //         title: "通讯终端",
        //         requireAuth: true,
        //         isMenu: true,
        //         right: "TERMINAL_DEVICES",
        //     }
        // },
        {
            path: '/electric-equipment/communication-cevice',
            name: 'communicationCevice',
            component: () => import('@/views/electricEquipment/communicationCevice'),
            meta: {
                title: "设备管理",
                requireAuth: true,
                isMenu: true,
                right: "DEVICES",
            }
        },
        {
            path: '/electric-equipment/communication-terminalinfo/:id',
            name: 'communicationTerminalInfo',
            component: () => import('@/views/electricEquipment/communicationTerminalInfo'),
            meta: {
                title: "终端详情",
                requireAuth: true,
                isMenu: false,
            }
        },
        ]
    },
    {
        path: '/water-equipment/list',
        component: Layout,
        redirect: "/water-equipment/list",
        meta: {
            title: "用水设备",
            requireAuth: true,
            isMenu: true,
            icon: 'water',
            right: "WATER_DEVICES",
        },
        children: [{
            path: '/water-equipment/list',
            name: 'waterEquipment',
            component: () => import('@/views/waterEquipment/index.vue'),
            meta: {
                title: "用水设备",
                requireAuth: true,
                isMenu: false,
                right: "WATER_DEVICES",
            }
        }]
    },
    {
        path: '/statistics',
        component: Layout,
        redirect: "/statistics/energy-consumption",
        redirect: "/statistics/energy-consumption",
        meta: {
            title: "数据统计",
            requireAuth: true,
            isMenu: true,
            icon: 'statistics',
            right: "STAT_DATA",
        },
        children: [{
            path: '/statistics/energy-consumption',
            name: 'energyConsumption',
            component: () => import('@/views/statistics/energyConsumption.vue'),
            meta: {
                title: "能耗分析",
                requireAuth: true,
                isMenu: true,
                right: "ENERFY_ANA",
            }
        },
        {
            path: '/statistics/electricity',
            name: 'electricity',
            component: () => import('@/views/statistics/electricity.vue'),
            meta: {
                title: "电费统计",
                requireAuth: true,
                isMenu: true,
                right: "ELE_FEE",
            }
        },
        {
            path: '/statistics/water',
            name: 'water',
            component: () => import('@/views/statistics/water.vue'),
            meta: {
                title: "水费统计",
                requireAuth: true,
                isMenu: true,
                right: "WATER_FEE",
            }
        },
        {
            path: '/statistics/room-cost-breakdown',
            name: 'roomCostBreakdown',
            component: () => import('@/views/statistics/roomCostBreakdown.vue'),
            meta: {
                title: "房间费用明细",
                requireAuth: true,
                isMenu: true,
                right: "ROOM_FEE",
            }
        },
        {
            path: '/statistics/electricity-log',
            name: 'electricityLog',
            component: () => import('@/views/statistics/electricityLog.vue'),
            meta: {
                title: "用电上传日志",
                requireAuth: true,
                isMenu: true,
                right: "ELE_LOG",
            }
        },
        {
            path: '/statistics/water-log',
            name: 'waterLog',
            component: () => import('@/views/statistics/waterLog.vue'),
            meta: {
                title: "用水上传日志",
                requireAuth: true,
                isMenu: true,
                right: "WATER_LOG",
            }
        }
        ]
    },
    {
        path: '/cost-assistance',
        component: Layout,
        redirect: "/cost-assistance/cost-grant",
        meta: {
            title: "费用补助",
            requireAuth: true,
            isMenu: true,
            icon: 'costAssistance',
            right: "ALLOWANCE",
        },
        children: [
            {
                path: '/cost-assistance/cost-grant',
                name: 'costGrant',
                component: () => import('@/views/costAssistance/costGrant'),
                meta: {
                    title: "费用补助发放",
                    requireAuth: true,
                    isMenu: true,
                    right: "ALLOWANCE_CREATE",
                }
            },
            {
                path: '/cost-assistance/grant-log',
                name: 'grantLog',
                component: () => import('@/views/costAssistance/grantLog'),
                meta: {
                    title: "补助发放记录",
                    requireAuth: true,
                    isMenu: true,
                    right: "ALLOWANCE_RECORDS",
                }
            },
        ]
    },
    {
        path: '/topUpAndPay',
        component: Layout,
        redirect: "/feeRecharge",
        meta: {
            title: "充值缴费",
            requireAuth: true,
            isMenu: true,
            icon: 'topUpAndPay',
            right: "RECHARGE_MANAGE",
        },
        children: [
            {
                path: '/topUpAndPay/feeRecharge',
                name: 'feeRecharge',
                component: () => import('@/views/topUpAndPay/feeRecharge'),
                meta: {
                    title: "费用充值",
                    requireAuth: true,
                    isMenu: true,
                    right: "RECHARGE",
                }
            },
            {
                path: '/topUpAndPay/rechargeLog',
                name: 'rechargeLog',
                component: () => import('@/views/topUpAndPay/rechargeLog'),
                meta: {
                    title: "充值记录",
                    requireAuth: true,
                    isMenu: true,
                    right: "RECHARGE_RECORDS",
                }
            },
            {
                path: '/topUpAndPay/costDeduction',
                name: 'costDeduction',
                component: () => import('@/views/topUpAndPay/costDeduction'),
                meta: {
                    title: "费用扣除/清零",
                    requireAuth: true,
                    right: "CLEAR",
                    isMenu: true,
                }
            },
            {
                path: '/topUpAndPay/deductionLog',
                name: 'deductionLog',
                component: () => import('@/views/topUpAndPay/deductionLog'),
                meta: {
                    title: "扣除/清零记录",
                    requireAuth: true,
                    isMenu: true,
                    right: "CLEAR_RECORDS",
                }
            },
            {
                path: '/topUpAndPay/roomRechargeLog',
                name: 'roomRechargeLog',
                component: () => import('@/views/topUpAndPay/roomRechargeLog.vue'),
                meta: {
                    title: "费用充值/充值记录",
                    requireAuth: true,
                    isMenu: false,
                }
            },
        ]
    },
    {
        path: '/billing-scheme/list',
        component: Layout,
        redirect: "/billing-scheme/list",
        meta: {
            title: "计费方案",
            requireAuth: true,
            isMenu: true,
            icon: 'electricEquipment',
            right: "FEE_PLAN",
        },
        children: [{
            path: '/billing-scheme/list',
            name: 'billingScheme',
            component: () => import('@/views/billingScheme/index.vue'),
            meta: {
                title: "计费方案",
                requireAuth: true,
                isMenu: false,
                right: "FEE_PLAN",
            }
        }]
    },
    {
        path: '/system-setting',
        component: Layout,
        redirect: "/system-setting",
        meta: {
            title: "系统设置",
            requireAuth: true,
            isMenu: true,
            icon: 'districtMange',
            right: "SYSTEM_CONFIG",
        },
        children: [{
            path: '/user-management',
            name: 'user-management',
            component: () => import('@/views/systemSetting/userManagement/index.vue'),
            meta: {
                title: "用户设置",
                requireAuth: true,
                isMenu: true,
                right: "USER_CONFIG",
            }
        }, {
            path: '/account-management',
            name: 'account-management',
            component: () => import('@/views/systemSetting/accountManagement/index.vue'),
            meta: {
                title: "账号管理",
                requireAuth: true,
                isMenu: true,
                right: "ACCOUNT_CONFIG",
            }
        }, {
            path: '/custom',
            name: 'custom',
            component: () => import('@/views/systemSetting/custom/index.vue'),
            meta: {
                title: "自定义设置",
                requireAuth: true,
                isMenu: true,
                right: "DIY_CONFING",
            }
        }]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
        meta: {
            title: "登录",
            isMenu: false,
        }
    },
    {
        path: '*',
        component: () => import('../views/error/NotFound.vue'),
        meta: {
            isMenu: false,
            isBreadcrumb: false,
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach(async (to, from, next) => {
    document.title = to.meta.title + '-智能水电计量管理系统';
    if (to.meta.requireAuth) {
        if (!utils.isLoginStatus()) {
            next({ path: "/login" })
        }
    } else {
        if (utils.isLoginStatus()) {
            if (to.path === "/login") {
                next({ path: '/' })
            }
        }
    }
    next()
})

export default router
export { routes }
