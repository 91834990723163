<template>
    <div class="logout-dailog">
        <dialog-box :visible="visible" title="退出登录" width="400">
            <div slot="content" class="content">
                <span>确认退出登录吗?</span>
            </div>
            <div slot="footer" class="footer">
                <basic-button color="#015C92" @handleClick="$emit('update:visible', false)">取消</basic-button>
                <basic-button background="#015C92" :plain="true" @handleClick="$emit('submit')">确认</basic-button>
            </div>
        </dialog-box>
    </div>
</template>

<script>
import DialogBox from '@/components/DialogBox/DialogBox.vue'
import BasicButton from "@/components/BasicButton/BasicButton.vue"
export default {
    name: "LogoutDialog",
    components: { DialogBox, BasicButton },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
    },
}
</script>