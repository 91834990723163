// 部门列表
import { departListApi } from '@/apis/system-setting/user-management.js';
export const useDepart = (key = '') => {
  const depart = {
    key,
    data: [],
    remote: async () => {
      const res = await departListApi({ keyword: depart.key });
      depart.data = res.data.department
    },
  };
  return depart;
}

// 储存系统信息
import { getSystemApi } from '@/apis/system-setting/custom'
import store from '@/store';
export const getSystemInfo = async () => {
  const res = await getSystemApi()
  if (res.code === 1) {
    const { logo, app_name, company } = res.data
    localStorage.setItem('system-info', JSON.stringify({ logo, app_name, company }))
    store.commit('setSystemInfo',{ logo, app_name, company })
  }
}

//  储存获取权限信息
import { accountRoleApi } from '@/apis/system-setting/account-management'
export const getRole = async (id) => {
  const res = await accountRoleApi({id})
  if (res.code === 1) {
    let roleList = []
    res.data.rights.forEach(item => {
      if(item.sub?.length) {
        item.sub.forEach(child => {
          if(!child.is_select) return
          roleList.push(child.right)
        })
      } else {
        if(!item.is_select) return
        roleList.push(item.right)
      }
    });
    localStorage.setItem('role-list', JSON.stringify(roleList))
    store.commit('setRoleList',roleList)
  }

}



