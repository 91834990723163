import md5 from 'js-md5'
import Cookies from 'js-cookie';
import * as XLSX from 'xlsx';

import { getSystemApi } from '@/apis/system-setting/custom'

export default {
    Md5(value) {
        return md5(value).toString();
    },
    isLoginStatus() {
        let Authorization = Cookies.get("Authorization")
        if (Authorization) {
            return Authorization;
        }
        return false;
    },
    getSystemInfo(){ // 获取系统信息
      const systemInfo = JSON.parse(localStorage.getItem('system-info') || '{}')
      return systemInfo
    },
    //导出excel表格 base64String-base64字符串  fileName-文件名非必传
    downloadBase64Excel(base64String, fileName) {
        // 将base64字符串转换为Blob对象
        let byteCharacters = atob(base64String);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        let blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // 创建一个链接并模拟点击以下载文件
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    // 表格内容本地导出
    exportXlsx(headers, properties, data, name) {
        const table = document.createElement('table');

        const list = data.map((item) => properties.map((key) => item[key] || ''));

        {
            const tr = document.createElement('tr');
            headers.forEach((header) => {
                const th = document.createElement('th');
                th.innerText = header;
                tr.appendChild(th);
            });
            table.appendChild(tr);
        }

        {
            list.forEach((item) => {
                const tr = document.createElement('tr');
                for (const d of item) {
                    const td = document.createElement('td');
                    td.innerText = d;
                    tr.appendChild(td);
                }
                table.appendChild(tr);
            });
        }

        const wb = XLSX.utils.table_to_book(table);
        XLSX.writeFile(wb, `${name}.xlsx`);
    },
    timestamp(timestamp) {
        timestamp = timestamp ? timestamp : null;
        let date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
        // let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return Y + M + D + h + m;
    }
}