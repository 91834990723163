<template>
    <div class="head-box">
        <h1>{{ $store.state.systemInfo.app_name }}</h1>
        <div class="userinfo">
            <el-dropdown placement="bottom">
                <div class="images">
                    <img @click="visibleAccount = true" src="@/assets/images/headpho.png" alt="">
                    <img src="@/assets/images/dropdown.png" alt="">
                </div>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="visiblePassword = true">修改密码</el-dropdown-item>
                    <el-dropdown-item @click.native="visibleLogout = true">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <password-dialog :visible.sync="visiblePassword"></password-dialog>
            <logout-dialog :visible.sync="visibleLogout" @submit="loginOut"></logout-dialog>
        </div>
    </div>
</template>

<script>
import Cookies from 'js-cookie';
import PasswordDialog from './PasswordDialog.vue'
import LogoutDialog from './LogoutDialog.vue'

export default {
    name: 'BasicHeader',
    components: { PasswordDialog, LogoutDialog },
    data() {
        return {
            visiblePassword: false,
            visibleLogout: false,
            visibleAccount: false
        }
    },
    created() {
    },
    methods: {
        loginOut(e) {
            Cookies.remove('Authorization')
            localStorage.clear()
            this.$router.push("/login")
        }
    }
}
</script>
<style lang='scss' scoped>
.head-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2D82B5;
    height: 50px;
    padding: 0px 12px;

    h1 {
        height: 18px;
        font-size: 16px;
        font-weight: 800;
        color: #FFFFFF;
        letter-spacing: 5px;
    }

    .userinfo {
        margin-right: 30px;
        cursor: pointer;

        .images {
            display: flex;
            align-items: center;

            // cursor: pointer;
            &>img:nth-child(1) {
                margin-right: 6px
            }
        }
    }
}
</style>